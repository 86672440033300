<template>
  <v-card>
    <v-data-table
      :headers="headerTableServer"
      :items="tableServerItem"
      v-model="selected"
      fixed-header
      item-key="name"
      :show-select="edit"
      multi-sort
      light
      :loading="loading"
      loading-text="Caricamento dati... Attendere, per favore."
      :items-per-page="10"
      class="main-table elevation-1 mt-0 theme--light"
      :item-class="itemRowBackground"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-dialog v-model="dialog" max-width="800px">
            <v-card>
              <v-card-text>
                <v-container
                  ><v-row>
                    <v-col
                      cols="12"
                      v-if="selected.length == tableServerItem.length"
                      class="bkgrdcolor2"
                    >
                      Stai modificando <u>TUTTI</u> gli elementi</v-col
                    >
                    <v-col
                      cols="12"
                      v-if="
                        selected.length > 1 &&
                        selected.length < tableServerItem.length
                      "
                      class="bkgrdcolor2"
                    >
                      Stai modificando più elementi</v-col
                    >
                    <v-col
                      cols="12"
                      v-if="selected.length == 1"
                      class="bkgrdcolor"
                    >
                      {{ editedItem.name }}</v-col
                    ></v-row
                  >
                  <v-row>
                    <v-col cols="6">
                      <v-select
                        v-model="editedItem.restoreEffettuato"
                        :items="['', 'SI', 'NO']"
                        label="Restore"
                        v-on:change="checkItem"
                      ></v-select>
                    </v-col>
                    <v-col cols="6">
                      <v-select
                        v-model="editedItem.infrStatus"
                        :items="selectItems"
                        label="Stato infra"
                        v-on:change="checkItem"
                      ></v-select>
                    </v-col>
                    <v-col cols="6">
                      <v-row>
                        <v-col cols="10">
                          <v-menu
                            ref="menu1"
                            v-model="menu1"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            max-width="290px"
                            min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                v-model="dateFormatted"
                                label="Data Completamento"
                                persistent-hint
                                prepend-icon="mdi-calendar"
                                v-bind="attrs"
                                readonly
                                @blur="date = parseDate(dateFormatted)"
                                v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="date"
                              no-title
                              @input="menu1 = false"
                            ></v-date-picker>
                          </v-menu>
                        </v-col>
                        <v-col cols="2" style="padding-top: 30px">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                rounded
                                icon
                                v-bind="attrs"
                                v-on="on"
                                @click="clearData()"
                              >
                                <v-icon>mdi-delete</v-icon>
                              </v-btn>
                            </template>
                            <span>Cancella data</span>
                          </v-tooltip>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="6">
                      <v-textarea
                        v-model="editedItem.note"
                        label="Note"
                        v-on:change="checkItem"
                        rows="1"
                      >
                      </v-textarea>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="black darken-1" text @click="close">
                  Annulla
                </v-btn>
                <v-btn
                  color="black darken-1"
                  text
                  @click="save"
                  :disabled="itemChanged"
                >
                  Conferma
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:header.actions="{ header }">
        <span style="color: #3b3b3b">Action</span>
        <v-btn
          :disabled="selected.length == 0"
          class="mr-2 actionBtn"
          @click="editItem()"
          ><v-icon small> mdi-pencil</v-icon></v-btn
        >{{ header.text }}
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn class="mr-2 actionBtn" @click="editItem(item)"
          ><v-icon small> mdi-pencil</v-icon></v-btn
        >
      </template>
    </v-data-table>

    <v-snackbar
      v-model="infoText"
      :timeout="3000"
      :color="snackColor"
      v-if="getActivation"
    >
      {{ modalText }}

      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" text @click="infoText = false"> Chiudi </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "TableServer",
  props: {
    tableServerItem: {
      default: () => [],
      Array,
    },
    edit: Boolean,
    key: Number,
  },
  data() {
    return {
      infoText: false,
      snackColor: "",
      modalText: "",
      max25chars: (v) => v.length <= 100 || "Testo troppo lungo",
      pagination: {},
      selected: [],
      dialog: false,
      loading: false,
      itemChanged: true,
      dateFormatted: "",
      editedIndex: -1,
      editedItem: {
        text: "",
        align: "",
        value: "",
        sortable: true,
        class: "",
      },
      menu1: false,
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      headers: [],
      selectItems: [
        { text: "NESSUNO", value: "" },
        { text: "COMPLETO", value: "Completo" },
        { text: "REWORK", value: "Rework" },
        { text: "REWORK HA", value: "Rework HA" },
        { text: "DISMESSO", value: "Dismesso" },
      ],
      restoreSelectItems: [
        { text: "NESSUNO", value: "" },
        { text: "SI", value: "si" },
        { text: "NO", value: "no" },
      ],
    };
  },
  methods: {
    save() {
      if (this.editedIndex > -1) {
        this.selected.forEach((el) => {
          if (el.name != this.editedItem.name) {
            if (
              this.tableServerItem[this.editedIndex].restoreEffettuato !==
              this.editedItem.restoreEffettuato
            ) {
              el.restoreEffettuato = this.editedItem.restoreEffettuato;
              el.edit = true;
            }
            if (
              this.tableServerItem[this.editedIndex].infrStatus !==
              this.editedItem.infrStatus
            ) {
              el.infrStatus = this.editedItem.infrStatus;
              el.edit = true;
            }
            if (
              this.tableServerItem[this.editedIndex].finishDate !==
              this.editedItem.finishDate
            ) {
              el.finishDate = this.editedItem.finishDate;
              el.edit = true;
            }
            if (
              this.tableServerItem[this.editedIndex].note !==
              this.editedItem.note
            ) {
              el.note = this.editedItem.note;
              el.edit = true;
            }
          }
        });

        this.selected.forEach((el) => {
          if (el.name == this.editedItem.name) {
            if (
              this.tableServerItem[this.editedIndex].restoreEffettuato !==
              this.editedItem.restoreEffettuato
            ) {
              el.restoreEffettuato = this.editedItem.restoreEffettuato;
              el.edit = true;
            }
            if (
              this.tableServerItem[this.editedIndex].infrStatus !==
              this.editedItem.infrStatus
            ) {
              el.infrStatus = this.editedItem.infrStatus;
              el.edit = true;
            }
            if (
              this.tableServerItem[this.editedIndex].finishDate !==
              this.editedItem.finishDate
            ) {
              el.finishDate = this.editedItem.finishDate;
              el.edit = true;
            }
            if (
              this.tableServerItem[this.editedIndex].note !==
              this.editedItem.note
            ) {
              el.note = this.editedItem.note;
              el.edit = true;
            }
          }
        });

        this.$emit("modifiedAdmin", this.tableServerItem);
        this.close();
      }
    },
    cancel() {
      this.infoText = true;
      this.snackColor = "error";
      this.modalText = "Cancellato";
    },
    open() {
      this.infoText = true;
      this.snackColor = "info";
      this.modalText = "Modifica";
    },
    itemRowBackground: function (item) {
      var index = this.tableServerItem.indexOf(item);
      return this.tableServerItem[index].edit == true ? "backgroundColor" : "";
    },
    editItem(item) {
      this.itemChanged = true;
      if (item) {
        if (this.selected.indexOf(item) == -1) {
          this.selected.push(item);
        }
        this.editedIndex = this.tableServerItem.indexOf(item);
        this.dateFormatted = item.dataCondivisione;
        this.editedItem = Object.assign({}, item);
      } else {
        this.tableServerItem.forEach((el) => {
          if (this.selected.indexOf(el) == -1) {
            this.selected.push(el);
          }
          this.editedIndex = this.tableServerItem.indexOf(el);
          this.editedItem = Object.assign({}, el);
        });
      }
      this.dialog = true;
    },
    clearData() {
      this.dateFormatted = "";
      this.editedItem.finishDate = "";
      this.checkItem();
    },
    formatDateTable(date) {
      if (!date) return "";
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [day, month, year].join("/");
    },
    formatDate(date) {
      if (!date) {
        this.editedItem.finishDate = "";
        this.checkItem();
        return null;
      }

      const [year, month, day] = date.split("-");
      this.editedItem.finishDate = `${day}/${month}/${year}`;
      this.checkItem();
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) {
        this.checkItem();
        return null;
      }
      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    checkItem() {
      if (this.editedIndex == -1) {
        this.editedIndex = 0;
      }
      if (
        this.tableServerItem[this.editedIndex].restoreEffettuato ==
          this.editedItem.restoreEffettuato &&
        this.tableServerItem[this.editedIndex].infrStatus ==
          this.tableServerItem.infrStatus &&
        this.tableServerItem[this.editedIndex].finishDate ==
          this.editedItem.finishDate &&
        this.tableServerItem[this.editedIndex].note == this.editedItem.note
      ) {
        this.itemChanged = true;
      } else {
        this.itemChanged = false;
      }
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    setHeader() {
      if (this.edit) {
        this.headers = [
          {
            align: "center",
            value: "actions",
            sortable: false,
            class: "primary lighten-4 white--text text-top",
            icon: "mdi-pencil",
            width: 50,
          },
          { text: "NODE", value: "name" },
          { text: "OWNER", value: "owner" },
          { text: "ACTION", value: "action" },
          { text: "TYPE", value: "type" },
          { text: "IS VIRTUAL", value: "isVirtual" },
          { text: "STATUS", value: "lifecycleStatus" },
          { text: "RESTORE", value: "restoreEffettuato" },
          { text: "STATO INFRA", value: "infrStatus" },
          { text: "DATA COMPLETAMENTO", value: "finishDate" },
          { text: "NOTE", value: "note" },
          { text: "INFO SYS", value: "infoSys" },
          { text: "INFO MONIT", value: "infoMonit" },
          { text: "STARDED FG", value: "startedFG" },
        ];
      } else {
        this.headers = [
          { text: "NODE", value: "name" },
          { text: "OWNER", value: "owner" },
          { text: "ACTION", value: "action" },
          { text: "TYPE", value: "type" },
          { text: "IS VIRTUAL", value: "isVirtual" },
          { text: "STATUS", value: "lifecycleStatus" },
          { text: "RESTORE", value: "restoreEffettuato" },
          { text: "STATO INFRA", value: "infrStatus" },
          { text: "DATA COMPLETAMENTO", value: "finishDate" },
          { text: "NOTE", value: "note" },
          { text: "INFO SYS", value: "infoSys" },
          { text: "INFO MONIT", value: "infoMonit" },
          { text: "STARDED FG", value: "startedFG" },
        ];
      }
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    date(val) {
      this.dateFormatted = this.formatDate(val);
    },
  },
  computed: {
    headerTableServer() {
      this.setHeader();
      return this.headers;
    },
    computedDateFormatted() {
      return this.formatDate(this.date);
    },

    ...mapGetters("operationalModule", ["getActivation"]),
  },
  beforeMount() {
    this.selected = [];
    this.tableServerItem.forEach((el) => {
      el.edit = false;
    });
  },
};
</script>

<style>
.v-small-dialog__activator {
  background-color: #aeaeae;
}
.bkgrdcolor.col.col-12 {
  background-color: #fdd300;
  color: black;
  margin-bottom: 10px;
  font-size: large;
  font-weight: bold;
  padding: 5px;
}
.bkgrdcolor2.col.col-12 {
  color: red;
  margin-bottom: 10px;
  font-size: large;
  font-weight: bold;
  padding: 5px;
}
.actionBtn {
  height: 0px !important;
  min-width: 0px !important;
  padding: 0 !important;
}
</style>
