<template>
  <v-card
    class="overflow-hidden"
    min-height="calc(100vh - 68px)"
    color="accent"
  >
    <v-overlay :value="displayProgress">
      <v-progress-circular
        :size="40"
        :width="4"
        color="amber"
        indeterminate
      ></v-progress-circular>
    </v-overlay>
    <template> <b-breadcrumb :items="breadCrumbs"></b-breadcrumb></template>

    <v-banner single-line sticky dark>
      <v-row>
        <v-col cols="2">
          <BackActionBar @goback="clickOnBack"></BackActionBar>
        </v-col>
        <v-col cols="8">
          <ContextualActionBar :title="titlePage"></ContextualActionBar>
        </v-col>

        <v-col cols="2" class="pr-3 pl-0 pt-6 text-right">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                rounded
                color="secondary"
                dark
                v-bind="attrs"
                v-on="on"
                class="ma-1 menuBtn"
                @click="scheda()"
                ><v-icon>mdi-card-text-outline</v-icon>
              </v-btn>
            </template>
            <span>SCHEDA</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                rounded
                color="secondary"
                dark
                v-bind="attrs"
                v-on="on"
                class="ma-1 menuBtn"
                @click="exportSystems"
                ><v-icon>mdi-download</v-icon>
              </v-btn>
            </template>
            <span>ESPORTA SISTEMI</span>
          </v-tooltip>
          <v-btn
            v-if="
              (this.editedTableValue.length == 0 && !this.changeDataInput) ||
              !this.getActivation ||
              lockedByOtherUser
            "
            rounded
            color="secondary"
            dark
            class="ma-1 menuBtn"
            @click="clicOnSave()"
            disabled
          >
            <v-icon>mdi-content-save</v-icon>
          </v-btn>
          <v-tooltip bottom v-else>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                rounded
                color="secondary"
                dark
                v-bind="attrs"
                v-on="on"
                class="ma-1 menuBtn"
                @click="clicOnSave()"
              >
                <v-icon>mdi-content-save</v-icon>
              </v-btn>
            </template>
            <span>SALVA</span>
          </v-tooltip>
          <v-btn
            v-if="this.editedTableValue.length == 0 && !this.changeDataInput"
            rounded
            color="secondary"
            dark
            class="ma-1 menuBtn"
            @click="clicOnReset()"
            disabled
          >
            <v-icon>mdi-refresh</v-icon>
          </v-btn>
          <v-tooltip bottom v-else>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                rounded
                color="secondary"
                dark
                v-bind="attrs"
                v-on="on"
                class="ma-1 menuBtn"
                @click="clicOnReset()"
              >
                <v-icon>mdi-refresh</v-icon>
              </v-btn>
            </template>
            <span>RESET</span>
          </v-tooltip>
        </v-col>
      </v-row>
      <v-alert type="success" v-if="successMsg">
        Salvataggio avvenuto con successo.
      </v-alert>
      <v-alert type="error" v-if="errorMsg">
        Errore durante il salvataggio.
      </v-alert>
      <v-sheet
        id="scrolling-techniques-7"
        class="overflow-y-aprische pt-0"
        max-height="calc(100vh - 157px)"
        color="accent"
        ><perfect-scrollbar>
          <v-container fluid>
            <v-row>
              <v-col
                cols="12"
                class="d-flex flex-row justify-start align-center"
              >
                <h6>
                  <span
                    class="text-caption text-uppercase font-weight-medium secondary--text"
                    >FG</span
                  >&nbsp;
                  <em class="font-weight-regular">{{ dataService.fgName }}</em>
                  &emsp;
                  <span
                    class="text-caption text-uppercase font-weight-medium secondary--text"
                    >Referente Operativo</span
                  >&nbsp;
                  <em class="font-weight-regular">{{
                    dataService.referente
                  }}</em>
                </h6>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="secondary padding">APP IMPATTATA</v-col>
              <v-col cols="3">
                <span
                  class="text-caption text-uppercase font-weight-medium secondary--text"
                  >STATO ATTIVAZIONE
                </span>
                <p class="font-weight-regular">
                  <v-icon :class="activationStateValues[0]"
                    >mdi-square-rounded</v-icon
                  >
                  {{
                    activationStateValues[1] ? activationStateValues[1] : "N/A"
                  }}
                </p>
              </v-col>

              <v-col cols="3">
                <span
                  class="text-caption text-uppercase font-weight-medium secondary--text"
                  >PRIORITY GROUP
                </span>
                <p class="font-weight-regular">
                  {{
                    dataService.priorityGroup
                      ? dataService.priorityGroup
                      : "N/A"
                  }}
                </p></v-col
              >
              <v-col cols="3">
                <span
                  class="text-caption text-uppercase font-weight-medium secondary--text"
                  >PRIORITÀ INSERITA
                </span>
                <p class="font-weight-regular">
                  {{
                    dataService.insertedPriority
                      ? dataService.insertedPriority
                      : "N/A"
                  }}
                </p></v-col
              >
              <v-col cols="3">
                <span
                  class="text-caption text-uppercase font-weight-medium secondary--text"
                  >PRIORITÀ RID
                </span>
                <p class="font-weight-regular">
                  {{
                    dataService.ridPriority ? dataService.ridPriority : "N/A"
                  }}
                </p></v-col
              >
              <v-col cols="3">
                <span
                  class="text-caption text-uppercase font-weight-medium secondary--text"
                  >PROBABILITÀ IMPATTO
                </span>
                <p class="font-weight-regular">
                  {{
                    dataService.probabilitaDiImpatto
                      ? dataService.probabilitaDiImpatto
                      : "N/A"
                  }}
                </p></v-col
              >
              <v-col cols="3">
                <span
                  class="text-caption text-uppercase font-weight-medium secondary--text"
                  >SLA
                </span>
                <p class="font-weight-regular">
                  {{ dataService.sla ? dataService.sla : "N/A" }}
                </p></v-col
              >
              <v-col cols="3">
                <span
                  class="text-caption text-uppercase font-weight-medium secondary--text"
                  >RTO
                </span>
                <p class="font-weight-regular">
                  {{ dataService.rto ? dataService.rto : "N/A" }}
                </p></v-col
              >
              <v-col cols="3">
                <span
                  class="text-caption text-uppercase font-weight-medium secondary--text"
                  >RPO
                </span>
                <p class="font-weight-regular">
                  {{ dataService.rpo ? dataService.rpo : "N/A" }}
                </p></v-col
              >
            </v-row>
            <v-row v-if="getActivation && !lockedByOtherUser">
              <v-col cols="2">
                <v-select
                  label="INSERITA IN PIPELINE"
                  name="pipeline"
                  id="pipeline"
                  v-model="dataService.inseritaInPipeline"
                  :items="inseritaInPipelineItem"
                  outlined
                  dense
                  hide-details
                  class="pt-2"
                  @change="changeData()"
                >
                </v-select>
              </v-col>
              <v-col cols="2">
                <v-select
                  label="MAIL APP COMPLETA TO CC1"
                  name="mailAppCc1"
                  id="mailAppCc1"
                  v-model="dataService.mailAppCompletaToCC1"
                  :items="mailAppCompletaToCC1Item"
                  outlined
                  dense
                  hide-details
                  class="pt-2"
                  @change="changeData()"
                >
                </v-select>
              </v-col>
              <v-col cols="2">
                <v-select
                  label="MAIL RILASCIO CC1TO AM"
                  name="mailCc1toAm"
                  id="mailCc1toAm"
                  v-model="dataService.mailRilascioCc1toAm"
                  :items="mailRilascioCc1toAmItem"
                  outlined
                  dense
                  hide-details
                  class="pt-2"
                  @change="changeData()"
                >
                </v-select>
              </v-col>
              <v-col cols="2">
                <v-select
                  label="FEEDBACK AM"
                  name="feddbakAm"
                  id="feddbakAm"
                  v-model="dataService.feedbackAm"
                  :items="feedbackAmItem"
                  outlined
                  dense
                  hide-details
                  @change="changeData()"
                  class="pt-2"
                >
                </v-select>
              </v-col>
              <v-col cols="2">
                <v-row>
                  <v-col cols="10" style="padding-top: 7px">
                    <v-menu
                      ref="menu1"
                      v-model="menu1"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="dataService.dataVerificaAm"
                          label="DATA VERIFICA AM"
                          persistent-hint
                          v-bind="attrs"
                          readonly
                          outlined
                          dense
                          v-on="on"
                          @change="changeData()"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="date"
                        no-title
                        @input="menu1 = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col
                    cols="1"
                    style="
                      padding-left: 0;
                      margin-left: -15px;
                      margin-top: -6px;
                    "
                  >
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          rounded
                          icon
                          v-bind="attrs"
                          v-on="on"
                          @click="clearData()"
                        >
                          <v-icon>mdi-delete</v-icon>
                        </v-btn>
                      </template>
                      <span>Cancella data</span>
                    </v-tooltip>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="2" style="padding-top: 7px">
                <v-textarea
                  v-model="dataService.note"
                  label="NOTE"
                  outlined
                  dense
                  hide-details
                  class="pt-2"
                  rows="1"
                  @change="changeData()"
                >
                </v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="secondary padding" padding>
                SERVER IMPATTATI</v-col
              >
              <v-col cols="12"
                ><TableServer
                  v-if="!resetEvent"
                  :tableServerItem="dataService.nodesInPlan"
                  @modifiedAdmin="fromChildTable"
                  :edit="getActivation && !lockedByOtherUser"
                  :key="tableServerReload"
                ></TableServer></v-col
            ></v-row>
          </v-container>
        </perfect-scrollbar>
      </v-sheet>
    </v-banner>
    <ModalConfirm
      @open-dialog-confirm="updateDialogConfirm"
      :dialogConfirm="openDialogConfirm"
      :text="dialogText"
      v-on:confirmed="save()"
    ></ModalConfirm>
  </v-card>
</template>

<script>
import connectionMixin from "@/mixins/connectionParams.js";
import ContextualActionBar from "../components/core/ContextualActionBar.vue";
import TableServer from "../components/core/TableServer.vue";
import { mapActions, mapGetters } from "vuex";
import ModalConfirm from "@/components/ModalConfirm.vue";
import BackActionBar from "../components/core/BackActionBar.vue";
import download from "downloadjs";
export default {
  name: "OpEditServiceProgress",
  components: {
    ContextualActionBar,
    TableServer,
    ModalConfirm,
    BackActionBar,
  },
  mixins: [connectionMixin],

  data() {
    return {
      lockedByOtherUser: true,
      lockId: "",
      dataService: {},
      fgdata: {},
      idPlan: this.$route.params.planId,
      items: [],
      textUpdate: "",
      disableAlert: false,
      displayProgress: true,
      impactSummaryValues: [],
      openDialogUpdatePlan: false,
      openDialogConfirm: false,
      editedTableValue: [],
      dialogText: "",
      resetEvent: false,
      successMsg: false,
      errorMsg: false,
      titlePage: "",
      changeDataInput: false,
      dateFormatted: "",
      menu1: false,
      date: null,
      activationStates: {
        notPlanned: { color: "grey--text", label: "Not planned" },
        rework: { color: "red--text", label: " Re-Work f" },
        infraServiceOk: {
          color: "green--text",
          label: " Infrastructural Service OK ",
        },
        infraCompleted: {
          color: "green--text",
          label: " Infra Completed ",
        },
        inPipeline: { color: "indigo--text", label: " In pipeline " },
        planned: { color: "amber--text", label: " Planned " },
        businessServiceOk: {
          color: "blue--text",
          label: " Business Service OK ",
        },
        businessServiceCompleted: {
          color: "blue--text",
          label: " Business Service Completed ",
        },
        amCheck: { color: "purple--text", label: " AM check " },
        toBeNotifiedToCC1: {
          color: "purple--text",
          label: " To be notified to CC1 ",
        },
        default: { color: "grey--text", label: " Non Avviato " },
      },
      inseritaInPipelineItem: ["", "OK"],
      mailAppCompletaToCC1Item: ["", "N/A", "INVIATA"],
      mailRilascioCc1toAmItem: ["", "INVIATA"],
      feedbackAmItem: ["", "OK", "REWORK"],
      breadCrumbs: [],
      tableServerReload: 0,
    };
  },
  beforeMount() {
    this.date = this.toReverseDashDate();
  },
  mounted() {
    this.getUserProfile();
  },
  computed: {
    ...mapGetters("operationalModule", ["getActivation"]),
    activationStateValues: function () {
      if (this.getActivation && this.fgdata.activationState) {
        var colorstate =
          this.activationStates[
            this.parseActivationState(this.fgdata.activationState)
          ].color;
        var labelState =
          this.activationStates[
            this.parseActivationState(this.fgdata.activationState)
          ].label;
        return [colorstate, labelState];
      } else {
        return ["grey--text", "non avviato"];
      }
    },
  },
  beforeDestroy() {
    this.releaseEditLock();
  },
  methods: {
    ...mapActions("operationalModule", ["activate"]),
    toReverseDashDate(date = null) {
      if (!date || date === "") return null;

      let [day, month, year] = ["", "", ""];
      try {
        if (date.includes("-") && !date.includes("/")) {
          [day, month, year] = new Date(date).toLocaleDateString().split("/");
        } else {
          [day, month, year] = date.split("/");
        }
        return `${year}-${month}-${day}`;
      } catch {
        [day, month, year] = new Date().toLocaleDateString().split("/");
        return `${year}-${month}-${day}`;
      }
    },
    onStart() {
      this.checkStatusPlan();
      this.fgdata = JSON.parse(localStorage.getItem("fgDataService"));
      this.titlePage = "Piano Operativo - Dettaglio " + this.fgdata.name;
      this.getDataService();
      this.getDataServiceAfterUpdate();
      if (this.getActivation) {
        this.lockEditResource();
        this.breadCrumbs = [
          {
            text: "Home",
            to: "/",
          },
          {
            text: "Piano operativo",
            to: "/operationalplan",
          },
          {
            text: "Dashboard",
            to: "/opplanimpactandprogress/" + this.fgdata.idPlan,
          },

          {
            text: "Failover Group",
            to: "/opplanfailovergroups/" + this.fgdata.idPlan,
          },
          {
            text: "Dettaglio",
            to: {
              name: "OpPlanFailoverGroupsDetails",
              params: {
                fgdata: this.fgdata,
                planId: this.fgdata.idPlan,
                failoverGroupId: this.fgdata.idFailover,
              },
            },
          },
          {
            text: "Servizio",
            href: "#",
          },
        ];
      } else {
        this.breadCrumbs = [
          {
            text: "Home",
            to: "/",
          },
          {
            text: "Piano operativo",
            to: "/operationalplan",
          },
          {
            text: "Dashboard",
            to: "/opplanimpact/" + this.fgdata.idPlan,
          },

          {
            text: "Failover Group",
            to: "/opplanfailovergroups/" + this.fgdata.idPlan,
          },
          {
            text: "Dettaglio",
            to: {
              name: "OpPlanFailoverGroupsDetails",
              params: {
                fgdata: this.fgdata,
                planId: this.fgdata.idPlan,
                failoverGroupId: this.fgdata.idFailover,
              },
            },
          },
          {
            text: "Servizio",
            href: "#",
          },
        ];
      }
    },
    async lockEditResource() {
      this.displayProgress = true;
      try {
        const response = await this.$http.get(
          this.$env.value("API_HOST_STORE") +
            "/edit/sip/" +
            this.dataService.id,
          this.opts
        );
        console.log("lockEditResource", response.data);
        this.lockId = response.data.id;
        this.lockedByOtherUser = false;
        this.displayProgress = false;
      } catch (error) {
        console.log("error", error);
        if (error.response.status == 423) {
          alert(error?.response.data.username + " sta modificando la risorsa!");
          this.lockedByOtherUser = true;
        } else {
          alert("Si prega di ricaricare la pagina");
        }
        this.displayProgress = false;
      }
    },
    async releaseEditLock() {
      if (!this.lockId) {
        return;
      }
      this.displayProgress = true;
      try {
        const response = await this.$http.get(
          this.$env.value("API_HOST_STORE") + "/edit/delete/" + this.lockId,
          this.opts
        );
        console.log("releaseEditLock", response.data);
        this.lockId = "";
        this.displayProgress = false;
      } catch (error) {
        console.log("error", error);
        alert("Si prega di ricaricare la pagina");
        this.displayProgress = false;
      }
    },
    async getUserProfile() {
      this.displayProgress = true;
      try {
        const response = await this.$http.get(
          this.$env.value("API_USER"),
          this.opts
        );
        if (response.data == "readonly") {
          this.$router.push("/");
        } else {
          this.onStart();
        }
        this.loading = false;
      } catch (error) {
        console.log(error);
        alert("Si prega di ricaricare la pagina");
        this.displayProgress = false;
      }
    },
    parseActivationState(val) {
      let activationStates;
      switch (val) {
        case "NOT planned":
          activationStates = "notPlanned";
          break;
        case "Infrastructural Service OK":
          activationStates = "infraServiceOk";
          break;
        case "Business Service OK":
          activationStates = "businessServiceOk";
          break;
        case "Rework":
          activationStates = "rework";
          break;
        case "AM Check":
          activationStates = "amCheck";
          break;
        case "Infra Completed":
          activationStates = "infraCompleted";
          break;
        case "Business service Completed":
          activationStates = "businessServiceCompleted";
          break;
        case "To be notified to CC1":
          activationStates = "toBeNotifiedToCC1";
          break;
        case "In pipeline":
          activationStates = "inPipeline";
          break;
        case "Planned":
          activationStates = "planned";
          break;
        default:
          activationStates = "default";
      }
      return activationStates;
    },
    checkStatusPlan() {
      let isActive = localStorage.getItem("planIsActive");
      if (isActive == "false") {
        this.activate(false);
      } else {
        this.activate(true);
      }
    },
    goback() {
      this.$router.push({
        name: "OpPlanFailoverGroupsDetails",
        params: {
          fgdata: this.fgdata,
          planId: this.fgdata.idPlan,
          failoverGroupId: this.fgdata.idFailover,
        },
      });
    },
    async getDataService() {
      this.displayProgress = true;
      this.loading = true;
      this.dataService = this.fgdata;
      this.loading = false;
      this.displayProgress = false;

      this.date = this.toReverseDashDate(this.dataService.dataVerificaAm);
      this.dateFormatted = this.dataService.dataVerificaAm || "";
    },

    checkData() {
      var date1 = this.dataService.startDateProcessing.split("/");
      var newDate = date1[1] + "/" + date1[0] + "/" + date1[2];

      var dateStart = new Date(newDate);
      var dataTocheck = new Date(this.dataService.dataVerificaAm);

      if (dataTocheck && dateStart > dataTocheck) {
        alert(
          "la data non può essere antecedente del: " +
            this.dataService.startDateProcessing
        );
        this.dataService.dataVerificaAm = "";
      }
    },
    async updateServiceInPlan() {
      var bodyData = {
        idPlan: this.fgdata.idPlan,
        serviceInPlan: [this.dataService],
      };
      this.displayProgress = true;
      try {
        const response = await this.$http.post(
          this.$env.value("API_HOST") +
            this.$env.value("API_OPLAN") +
            this.$env.value("API_UPDATE_SERVICE_IN_PLAN"),
          bodyData,
          this.opts
        );
        console.log(response);
        this.displayProgress = false;
        this.successMsg = true;
        this.getDataServiceAfterUpdate();

        setInterval(() => {
          this.successMsg = false;
        }, 10000);
      } catch (error) {
        console.log(error);
        this.displayProgress = false;
        this.errorMsg = true;
        setInterval(() => {
          this.errorMsg = false;
        }, 10000);
      }
    },
    async getDataServiceAfterUpdate() {
      console.log("getDataServiceAfterUpdate");
      var idPlan = this.dataService.idPlan;
      var idFailoverGroup = this.dataService.idFailover;
      var id = this.dataService.id;
      console.log(idPlan, idFailoverGroup, id);
      this.displayProgress = true;
      try {
        const response = await this.$http.get(
          this.$env.value("API_HOST_STORE") +
            this.$env.value("API_OPLANS") +
            "/" +
            idPlan +
            "/" +
            idFailoverGroup +
            "/" +
            id,
          this.opts
        );

        this.checkActivationState(response.data.activationState);
        this.dataService = response.data;
        this.dataService.idPlan = idPlan;
        this.dataService.idFailover = idFailoverGroup;
        this.dataService.id = id;
        this.displayProgress = false;
      } catch (error) {
        console.log(error);
        alert(
          "Qualcosa è andato storto. Non è stato possibile ricaricare il servizio. Riprova più tardi"
        );
        this.displayProgress = false;
      }
    },
    updateDialogConfirm(value) {
      this.openDialogConfirm = value;
    },
    checkActivationState(state) {
      if (this.getActivation && state) {
        var colorstate =
          this.activationStates[this.parseActivationState(state)].color;
        var labelState =
          this.activationStates[this.parseActivationState(state)].label;
        this.activationStateValues[0] = colorstate;
        this.activationStateValues[1] = labelState;
      } else {
        this.activationStateValues[0] = "grey--text";
        this.activationStateValues[1] = "non avviato";
      }
    },
    scheda() {
      this.$router.push({
        name: "OperatingCardDetails",
        params: {
          id: this.dataService.id,
          fgdata: this.fgdata,
        },
      });
    },
    clicOnSave() {
      this.dialogText = "Vuoi confermare le modifiche inserite?";
      this.updateDialogConfirm(true);
    },
    async exportSystems() {
      var idPlan = this.dataService.idPlan;
      var idFailoverGroup = this.dataService.idFailover;
      var id = this.dataService.id;
      this.displayProgress = true;

      const endpoint =
        this.$env.value("API_HOST_REPORT") +
        this.$env.value("API_EXPORT_IMP_NODES") +
        "/" +
        idPlan +
        "/" +
        idFailoverGroup +
        "/" +
        id;

      console.log(endpoint);

      try {
        const response = await this.$http.get(endpoint, this.optsXls);
        console.log(response);
        const content =
          response.headers[
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          ];
        download(response.data, "export-sistemi.xlsx", content);
        this.displayProgress = false;
      } catch (error) {
        console.log(error);
        alert("non è stato possibile esportare i dati. Riprovare più tardi");
        this.displayProgress = false;
      }
    },
    clicOnReset() {
      this.dialogText = "Vuoi resettare le modifiche?";
      this.updateDialogConfirm(true);
    },
    clickOnBack() {
      if (this.editedTableValue.length > 0) {
        this.dialogText = "Tornando indietro perderai le modifiche";
        this.updateDialogConfirm(true);
      } else {
        this.goback();
      }
    },
    save() {
      if (this.dialogText === "Vuoi confermare le modifiche inserite?") {
        this.updateServiceInPlan();
        this.changeDataInput = false;
        this.editedTableValue = [];
        this.tableServerReload++;
        this.updateDialogConfirm(false);
      }
      if (this.dialogText === "Vuoi resettare le modifiche?") {
        this.reset();
        this.changeDataInput = false;
        this.updateDialogConfirm(false);
      }
      if (this.dialogText === "Tornando indietro perderai le modifiche") {
        this.goback();
        this.updateDialogConfirm(false);
      }
    },
    reset() {
      this.resetEvent = true;
      this.displayProgress = true;
      setTimeout(() => {
        this.resetEvent = false;
        this.displayProgress = false;
        this.dataService = JSON.parse(localStorage.getItem("fgDataService"));
        this.editedTableValue = [];
      }, 500);
    },
    fromChildTable(value) {
      this.editedTableValue = value;
    },
    parseDate(date) {
      if (!date || date === "") return;

      const calculatedDate = new Date(date).toLocaleDateString();
      this.dataService.finishDate = calculatedDate;
      this.dataService.dataVerificaAm = calculatedDate;

      return calculatedDate;
    },
    clearData() {
      this.dataService.dataVerificaAm = null;
      this.date = null;
    },
    changeData() {
      this.changeDataInput = true;
    },
  },
  watch: {
    date(val) {
      this.dateFormatted = this.parseDate(val);
      this.changeData();
    },
  },
};
</script>

<style scoped>
.ps {
  height: calc(100vh - 201px);
  width: 100%;
}
.hide {
  display: none !important;
}
.show {
  display: flex !important;
}
input,
textarea,
v-select {
  background-color: #aeaeae;
  width: 100%;
}
.padding {
  padding-left: 25px;
}
.col-3 {
  padding-top: 20px;
}
</style>
