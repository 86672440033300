<template>
  <v-dialog
    v-model="dialogConfirm"
    scrollable
    width="500"
    @click:outside="closeDialogConfirm()"
  >
    <v-card>
      <v-card-title>
        <v-col cols="12" class="text-center"> {{ text }} </v-col></v-card-title
      >
      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="closeDialogConfirm">
          Annulla
        </v-btn>
        <v-btn color="primary" text @click="confirmDialogConfirm">
          Conferma
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    text: {
      default: "",
      String,
    },
    dialogConfirm: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {};
  },
  computed: {},
  methods: {
    closeDialogConfirm() {
      this.$emit("open-dialog-confirm", false);
    },
    confirmDialogConfirm() {
      this.$emit("confirmed");
    },
  },
};
</script>

<style scoped>
.v-list-item p {
  margin-bottom: 0px;
  margin-top: 8px;
}
.text-center {
  text-align: center;
}
</style>
