var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-data-table',{staticClass:"main-table elevation-1 mt-0 theme--light",attrs:{"headers":_vm.headerTableServer,"items":_vm.tableServerItem,"fixed-header":"","item-key":"name","show-select":_vm.edit,"multi-sort":"","light":"","loading":_vm.loading,"loading-text":"Caricamento dati... Attendere, per favore.","items-per-page":10,"item-class":_vm.itemRowBackground},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-dialog',{attrs:{"max-width":"800px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-text',[_c('v-container',[_c('v-row',[(_vm.selected.length == _vm.tableServerItem.length)?_c('v-col',{staticClass:"bkgrdcolor2",attrs:{"cols":"12"}},[_vm._v(" Stai modificando "),_c('u',[_vm._v("TUTTI")]),_vm._v(" gli elementi")]):_vm._e(),(
                      _vm.selected.length > 1 &&
                      _vm.selected.length < _vm.tableServerItem.length
                    )?_c('v-col',{staticClass:"bkgrdcolor2",attrs:{"cols":"12"}},[_vm._v(" Stai modificando più elementi")]):_vm._e(),(_vm.selected.length == 1)?_c('v-col',{staticClass:"bkgrdcolor",attrs:{"cols":"12"}},[_vm._v(" "+_vm._s(_vm.editedItem.name))]):_vm._e()],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-select',{attrs:{"items":['', 'SI', 'NO'],"label":"Restore"},on:{"change":_vm.checkItem},model:{value:(_vm.editedItem.restoreEffettuato),callback:function ($$v) {_vm.$set(_vm.editedItem, "restoreEffettuato", $$v)},expression:"editedItem.restoreEffettuato"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-select',{attrs:{"items":_vm.selectItems,"label":"Stato infra"},on:{"change":_vm.checkItem},model:{value:(_vm.editedItem.infrStatus),callback:function ($$v) {_vm.$set(_vm.editedItem, "infrStatus", $$v)},expression:"editedItem.infrStatus"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"10"}},[_c('v-menu',{ref:"menu1",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Data Completamento","persistent-hint":"","prepend-icon":"mdi-calendar","readonly":""},on:{"blur":function($event){_vm.date = _vm.parseDate(_vm.dateFormatted)}},model:{value:(_vm.dateFormatted),callback:function ($$v) {_vm.dateFormatted=$$v},expression:"dateFormatted"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu1),callback:function ($$v) {_vm.menu1=$$v},expression:"menu1"}},[_c('v-date-picker',{attrs:{"no-title":""},on:{"input":function($event){_vm.menu1 = false}},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1)],1),_c('v-col',{staticStyle:{"padding-top":"30px"},attrs:{"cols":"2"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"rounded":"","icon":""},on:{"click":function($event){return _vm.clearData()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}])},[_c('span',[_vm._v("Cancella data")])])],1)],1)],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-textarea',{attrs:{"label":"Note","rows":"1"},on:{"change":_vm.checkItem},model:{value:(_vm.editedItem.note),callback:function ($$v) {_vm.$set(_vm.editedItem, "note", $$v)},expression:"editedItem.note"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"black darken-1","text":""},on:{"click":_vm.close}},[_vm._v(" Annulla ")]),_c('v-btn',{attrs:{"color":"black darken-1","text":"","disabled":_vm.itemChanged},on:{"click":_vm.save}},[_vm._v(" Conferma ")])],1)],1)],1)],1)]},proxy:true},{key:"header.actions",fn:function(ref){
                    var header = ref.header;
return [_c('span',{staticStyle:{"color":"#3b3b3b"}},[_vm._v("Action")]),_c('v-btn',{staticClass:"mr-2 actionBtn",attrs:{"disabled":_vm.selected.length == 0},on:{"click":function($event){return _vm.editItem()}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-pencil")])],1),_vm._v(_vm._s(header.text)+" ")]}},{key:"item.actions",fn:function(ref){
                    var item = ref.item;
return [_c('v-btn',{staticClass:"mr-2 actionBtn",on:{"click":function($event){return _vm.editItem(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-pencil")])],1)]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),(_vm.getActivation)?_c('v-snackbar',{attrs:{"timeout":3000,"color":_vm.snackColor},scopedSlots:_vm._u([{key:"action",fn:function(ref){
                    var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"text":""},on:{"click":function($event){_vm.infoText = false}}},'v-btn',attrs,false),[_vm._v(" Chiudi ")])]}}],null,false,3800259747),model:{value:(_vm.infoText),callback:function ($$v) {_vm.infoText=$$v},expression:"infoText"}},[_vm._v(" "+_vm._s(_vm.modalText)+" ")]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }